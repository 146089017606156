/*
 * Made by Erik Terwan
 * 24th of November 2015
 * All rights reserved
 *
 *
 * If you are thinking of using this in
 * production code, beware of the browser
 * prefixes.
 */
$transitionMenu: 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0); // MenuToggleMove

#menuToggle {
  position: absolute;
  right: 10px;
  display: block;
  top: 14px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;

  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }
  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: $primaryColor;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform $transitionMenu,
    background $transitionMenu,
    opacity 0.55s ease;
  }
  span:first-child {
    transform-origin: 0% 0%;
  }
  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;

    &:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    &:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
  input:checked ~ ul {
    transform: none;
  }
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -228px;
  padding: 50px;
  padding-top: 125px;
  height: 100vh;

  background: $primaryColor;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(100%, 0);

  transition: transform $transitionMenu;

  li {
    padding: 4px 0;
    font-size: 25px;
  }
  input:checked ~ a {
    transform: translate(100%, 0);
  }
}
