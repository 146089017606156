
// Couleurs
$primaryColor: #FED12F; // Vert
$primaryColorReverse: white;
$darkColorBackground: #000000; // Gris foncé

// Fontes
$titleFontFamily: 'Patua One', Helvetica, cursive;;
$generalFontFamily: 'Saira', Arial, Helvetica, sans-serif;
$generalFontColor: black;
$textShadow: 0 0 7px rgba(12, 12, 12, 0.58);

// $Header
$headerBackground: black;
$headerHeight: 100px;
$headerHeightMobile: 50px;

// button
$buttonTextColor: white;
$buttonPrimaryBackground: rgba(67, 67, 67, 0.76);
$buttonPrimaryBackgroundHover: rgba(47, 47, 47, 0.8);
$buttonSecondaryBackground: $primaryColor;

// form
$inputPlaceholder: #3b3c3c;

// $Footer
$footerBackground: $primaryColor;
$footerFontColor: black;
$ctaFooterBackground: $headerBackground;
$ctaFooterHeight: 60px;

// #LightboxCTA
$lightboxCTAbackground: rgba(255, 255, 255,.97);

// Sections
// - Secion punch
$sectionPunchOverlayBackground: rgba(0, 0, 0, 0.75);
$sectionPunchOverlayBackgroundDark: rgba(0, 0, 0, 0.95);

// Mobile breakooint
$mobileBreakpoint: 700px;
