.main {
  .section:first-of-type {
    margin-top: $headerHeightMobile;
    height: calc(100vh - #{$headerHeightMobile});
    @media only screen and (min-width: $mobileBreakpoint) {
      margin-top: $headerHeight;
      height: calc(100vh - #{$headerHeight});
      min-height: 550px;
    }
  }
}
