.indiv-box {

  > img {
    width: 100%;
    padding: 0;
    margin: 0 0 15px;
    float: left;
    background-color: black;
  }

  .box {
    width: 100%;
    text-align: left;
    padding: 2px 15px;
    min-height: 470px;
    font-family: $generalFontFamily;

    h3 {
      font-family: $titleFontFamily;
      text-align: center;
      font-size: 1.7em;
    }

    &.box-invert {
      background-color: $darkColorBackground;
      color: white;

      h3 {
        color: $primaryColor;
      }
    }

    p {
      line-height: 1.45;
    }

    &.box-colored {
      background-color: $primaryColor;
      color: $darkColorBackground;

      h3 {
        color: $darkColorBackground;
      }
    }
  }
}

a.promo-box {
  cursor: pointer;
  font-family: $generalFontFamily;
  opacity: 1;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
  z-index: 1;

  .middle {
    z-index: 2;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: white;
  }

  > img {
    width: 100%;
    padding: 0;
    margin: 0 0 15px;
    float: left;
    background-color: $darkColorBackground;
    transition: .5s ease-in-out;
    backface-visibility: hidden;
  }

  .box {
    width: 100%;
    text-align: left;
    padding: 2px 15px;
    min-height: 340px;
    opacity: 1;
    transition: .5s ease;

    h3 {
      font-family: $titleFontFamily;
      text-align: center;
      font-size: 1.7em;
    }

    &.box-invert {
      background-color: $darkColorBackground;
      color: white;

      h3 {
        color: $primaryColor;
      }
    }

    &.box-colored {
      background-color: $primaryColor;
      color: $darkColorBackground;

      h3 {
        color: $darkColorBackground;
      }
    }
  }

  &:hover {

    .middle {
      opacity: 1;
    }

    > img {
      transition: .5s ease-in-out;
      opacity: 0;
    }

    .box {
      opacity: 0.3;

      &.box-invert {

        h3 {
          font-family: $titleFontFamily;
          color: $primaryColor;
          text-align: center;
          font-size: 1.7em;
        }
      }

      &.box-colored {
        background-color: $primaryColor;
        color: $darkColorBackground;

        h3 {
          font-family: $titleFontFamily;
          color: $darkColorBackground;
          text-align: center;
          font-size: 1.7em;
        }
      }
    }
  }
}
