section.section.section-punch{
  @include sectionFlex();
  flex-direction: column;
  text-align: center;
  background-image:
     linear-gradient($sectionPunchOverlayBackgroundDark, $sectionPunchOverlayBackground),
     url("/assets/img/bkg_splash.jpg");
  background-repeat: no-repeat;
  background-size: auto, cover;
  h1{
    color:white;
    text-shadow: $textShadow;
  }
  .slogan {
    font-size: 2em;
  }
  .subslogan {
    font-size: 1.5em;
  }
}
