@media only screen and (min-width: 320px) {
}

@media only screen and (min-width: 480px) {

}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
  #menuToggle {
    display: none;
  }
  .menu {
    display: block;
  }
}

@media only screen and (min-width: 1140px) {

}

@media only screen and (min-width: 1280px) {

}

@media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {

}
