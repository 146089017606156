.menu {
  position: absolute;
  right: 0;
  display: none;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

    li {
      float: left;
      a {
        display: block;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-family: 'Saira', sans-serif;
        color: #FED12F;
        font-size: 1.4em;

        &:hover {
          color: #b48c2a;
        }

        &.phonenumber {
          font-size: 3em;
          color: #fff;
          margin-left: 55px;
          line-height: 0.8em;

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}
