a.button, button.button {
  color: $generalFontColor;
  text-decoration: none;
  transition: background-color 0.3s;
  text-transform: uppercase;
  cursor: pointer;
  &.button-primary {
    color: $buttonTextColor;
    background-color: $buttonPrimaryBackground;
    border: solid 3px white;
    padding: 1rem 3rem;
    text-decoration: none;
    &:hover {
      background-color: $buttonPrimaryBackgroundHover;
    }
  }
  &.button-circle {
    color: #fff;
    background-color: rgba(67, 67, 67, .76);
    border: 3px solid #fff;
    padding: 1rem 1.3rem;
    text-decoration: none;
    border-radius: 100%;
    &:hover {
      background-color: $buttonPrimaryBackgroundHover;
    }
  }
  &.button-secondary {
    background-color: $buttonSecondaryBackground;
    font-size: 1.6rem;
    padding: 0.6em 2em 1.2em 2em;
    display: block;
    top: -20px;
    position: relative;
    &:hover {
      background-color: lighten($buttonSecondaryBackground, 5%);
    }
    img {
      height: 42px;
    }
  }
}
