

.short-animate {
  transition:.5s ease-in-out;
}
.long-animate {
  transition:.5s .5s ease-in-out;
}

.lightbox-wrapper {
  position:fixed;
  top:-200%;
  bottom:100%;
  left:0;
  right:0;
  background:$lightboxCTAbackground;
  z-index:501;
  opacity:0;
  &:target {
    top: 0%;
    bottom: 0%;
    opacity: 1;
    ~ #lightbox-controls {
      top:0px;
    }
    ~ #lightbox-controls #close-lightbox:after {
      width:50px;
    }
    ~ #lightbox-controls #close-lightbox:before {
      height:50px;
    }
  }
  .lightbox-content {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateX(0);
  }
  a{
    color:white;
  }
  > img {
    position: absolute;
    width: 100%;
  }
}
#lightbox-controls {
  position:fixed;
  height:70px;
  width:70px;
  top:-70px;
  right:0;
  z-index:502;
  background:rgba(0,0,0,.1);
}
#close-lightbox{
  display:block;
  position:absolute;
  overflow:hidden;
  height:50px;
  width:50px;
  text-indent:-5000px;
  right:10px;
  top:10px;
  transform:rotate(45deg);
  &:before, &:after{
  	content:'';
    display:block;
    position:absolute;
    background:black;
    border-radius:2px;
  }
  &:before{
    height:0px;
    width:3px;
    left:24px;
    top:0;
    transition:.5s .5s ease-in-out;
  }
  &:after {
    width:0px;
    height:3px;
    top:24px;
    left:0;
    transition:.5s 1s ease-in-out;
  }
}
