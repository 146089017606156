section.section.section-features{
  @include sectionFlex();
  min-height: 40vh;
  text-align: center;
  padding-bottom: 2em;
  padding: 7.4em 0 5em 0;
  .feature{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom:3em;
  }
  i.fa{
    font-size:3em;
  }
  .description{
    font-size: 1.2em;
    text-transform: uppercase;
    margin-top:0.5em;
  }
  @media only screen and (min-width:$mobileBreakpoint) {
    i.fa{
      font-size:4em;
    }
    .description{
      max-width: 300px;
      margin-top:0;
      margin-left:1em;
      font-size: 1.3em;
    }
  }
}
