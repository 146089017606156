.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $headerHeightMobile;
  top:0;
  z-index: 10;
  width:100%;
  padding: 20px;
  background-color: $headerBackground;
  .logo {
    text-align: center;
    img{
      height: 40px;
    }
  }
  .button-cta{
    display: none;
  }
  @media only screen and (min-width:$mobileBreakpoint) {
    justify-content: space-between;
    height: $headerHeight;
    padding: 20px 30px;
    .logo {
      float:left;
      img{
        height: 50px;
      }
    }
    .button-cta{
      display: inherit;
      float: right;
    }
  }
}
