section.section{
  min-height: 85vh;
  overflow: auto; // bug fix
  h1, h2{
    margin-top: 0;
  }

  &.section-light{ // sections avec text clair sur fond foncé
    color: $darkColorBackground;
    text-shadow: none;
    background-color: white;
    h1, h2{
      color: $darkColorBackground;
      text-shadow: none;
    }
    .button{
      color:white;
    }

    &.about {
      background-image: url("/assets/img/bkg_history.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top right;
    }
  }

  &.section-invert{ // sections avec text clair sur fond foncé
    color: white;
    text-shadow: none;
    background-color: $darkColorBackground;
    h1, h2{
      color: $primaryColor;
      text-shadow: none;
    }
    .button{
      color:white;
    }
  }

  &.section-colored{ // sections avec text clair sur fond foncé
    color: white;
    text-shadow: none;
    background-color: $primaryColor;
    h1, h2{
      color: $darkColorBackground;
      text-shadow: none;
    }
    .button{
      color:white;
    }
  }
}

@mixin sectionFlex(){
  display: flex;
  align-items: center;
  justify-content: center;
  padding:5em 0;
  // Hack IE 11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
    flex: inherit;
  }
}

@import 'sections/punchline';
@import 'sections/media';
@import 'sections/video';
@import 'sections/features';
@import 'sections/information';
