// typography
h1, h2 {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  strong {
    font-weight: bold;
  }
  @media only screen and (min-width: $mobileBreakpoint) {
    font-size: 4.5rem;
  }
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.5em;
  font-weight: normal;
  strong {
    font-weight: normal;
  }
  @media only screen and (min-width: $mobileBreakpoint) {
    font-size: 2.15em;
  }
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

h1, h2, h3 {
  font-family: $titleFontFamily;
  line-height: 1.25em;
}

p {
  font-size: 1.6rem;
  font-weight: 200;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: $darkColorBackground;
  font-family: $titleFontFamily;

  transition: color 0.3s ease;
  &:hover {
    color: #484848;
  }
}
